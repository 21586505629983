.header {
    font-family: 'PT Sans';
    font-weight: bold;
}

.header_highlighted {
    font-family: 'PT Sans';
    font-weight: bold;
    background-color: pink;
}

.headerContainer {
    background-color: aqua;
}


.css-ur97uj,.css-1hx7vxy{
    z-index: 0 ;
}

.css-1dojw16,.css-r6z5ec{
    z-index: 99999 !important;
}